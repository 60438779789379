<template>
    <section class="new-ship">
    <form action="" @submit.prevent="updateShipment" id="inventory-new-ship" v-if="ship" @change="edited=true">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Shipments'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">{{ship.name}}</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary" :class="{'disabled':!edited}">
                        Update
                    </button>
                </fieldset>
            </div>
        </header>
        <div class="row">

            <div class="col-12 bship-bottom" v-if="ship && warehouses && manufacturers && orders && products"> 

                <div class="row">
                    <article class="col-12 col-lg-8">


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Shipment information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-date-signed" name="po-pickup_date" v-model="ship.pickup_date" required/>
                                    <label for="po-date-signed" class="mb-1">Pick Up date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <select id="po-status" class="form-select" aria-label="Active" v-model="ship.manufacturer" required>
                                        <option v-for="(manufacturer,index) in manufacturers" :key="manufacturer.id" :selected="{selected:(index == 0)}" :value="manufacturer.name">{{manufacturer.name}}</option>
                                    </select>
                                    <label for="po-status" class="mb-1">Manufacturer</label>
                                </fieldset>

                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Shipping</h3>
                            </header>
                            <div class="card-body">

                                

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-date-approved" name="po-date-approved" v-model="ship.est_ready_to_ship_date" required/>
                                    <label for="po-date-approved" class="mb-1">Est. Ready To Ship Date</label>
                                </fieldset>
                                

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-est-date" name="po-est-date" v-model="ship.actual_ship_date" required/>
                                    <label for="po-est-date" class="mb-1">Actual Ship Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-est-date" name="po-est-date" v-model="ship.est_port_date" required/>
                                    <label for="po-est-date" class="mb-1">Est. Port Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-est-date" name="po-est-date" v-model="ship.actual_port_date" required/>
                                    <label for="po-est-date" class="mb-1">Actual Port Date</label>
                                </fieldset>


                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Warehouse</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <select id="po-status" class="form-select" aria-label="Active" v-model="ship.warehouse" required>
                                        <option v-for="(warehouse,index) in warehouses" :key="warehouse.id" :selected="{selected:(index == 0)}" :value="warehouse">{{warehouse.name}}</option>
                                    </select>
                                    <label for="po-status" class="mb-1">Warehouse</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3" v-if="ship.warehouse">
                                    <select id="po-status" class="form-select" aria-label="Active" v-model="ship.warehouse_address" required>
                                        <option v-for="(address,index) in ship.warehouse.addresses" :key="address.street" :selected="{selected:(index == 0)}" :value="address">{{address | formatStreet}}</option>
                                    </select>
                                    <label for="po-status" class="mb-1">Address</label>
                                </fieldset>


                            </div>
                        </div>

                        <ProductSelectorUnits :products="ship.skus" :productsearch="products" :warehouses="warehouses"/>


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Customs</h3>
                            </header>
                            <div class="card-body">

                                

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="po-deposit-amount" name="po-deposit-amount" v-model="ship.customs.amount" required/>
                                    <label for="po-deposit-amount" class="mb-1">Customs Amount</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-deposit-date" name="po-deposit-date" v-model="ship.customs.date" required/>
                                    <label for="po-deposit-date" class="mb-1">Customs Due Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="po-deposit-amount" name="po-deposit-amount" v-model="ship.inbound_cost" required/>
                                    <label for="po-deposit-amount" class="mb-1">Inbound Cost</label>
                                </fieldset>


                            </div>
                        </div>

                    </article>
                    <aside class="col-12 col-lg-4">

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Plan status</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <select id="po-status" class="form-select" aria-label="Active" v-model="ship.status" required>
                                        <option selected value="In-progress">In-progress</option>
                                        <option selected value="Submitted">Submitted</option>
                                        <option selected value="In-Transit">In-Transit</option>
                                        <option selected value="Received">Received</option>
                                    </select>
                                    <label for="po-status" class="mb-1">Status</label>
                                </fieldset>

                            </div>
                        </div>

                        <Attachments :attachments="ship.attachments"/>
                        
                        <Orders v-if="orders" :ordersearch="orders" :orders="ship.pos"/>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header bship-0 mt-2">
                                <h3 class="h5 mb-0">Notes</h3>
                            </header>
                            <div class="card-body">

                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px" v-model="ship.notes"></textarea>
                                    <label for="floatingTextarea2">Notes</label>
                                </div>

                            </div>
                        </div>

                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon} from 'vue-feather-icons'
import ProductSelectorUnits from '@/components/productselect/ProductSelectorUnits.vue'
import Attachments from '@/components/productselect/Attachments.vue'
import Orders from '@/components/orders/OrderSelect.vue'

export default {
  name: 'DetailShipment',
  components: {
      ArrowLeftIcon,ProductSelectorUnits,Attachments,Orders
  },
  props: ['user'],
  data(){
    return {

        warehouses:null,
        manufacturers:null,
        products:null,
        orders:null,
        oldship:null,
        ship:null,
        edited:false,
        barcodelink:null
    }
  },
  updated(){
      
      this.edited = (this.oldship != JSON.stringify(this.ship) )
  },
  created(){

      if(!this.$route.params.ship) this.$router.push({name:'Shipments'})
      if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouses'})
      this.oldship = this.$route.params.ship
      this.warehouses = this.user.warehouses
      this.getShipments()
      this.getManufacturers()
      this.getProducts()
      this.reset()
  },
  filters:{
      formatDate: function(date){
        return !date ? '' :  moment(date).format('MM/DD/YYYY hh:mm')
      }
  },
  methods:{
      getManufacturers: function(){

        fetch('https://bestself-inventory-api.herokuapp.com//manufacturers')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.manufacturers = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    getShipments: function(){

        fetch('https://bestself-inventory-api.herokuapp.com//orders')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.orders = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    getProducts: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/products')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.products = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    updateShipment: function(){

        let id = this.ship.id
        let updatedShipment = this.ship
        delete updatedShipment.id

        db.collection("shipments").doc(id).update(updatedShipment)
        .then(() => {
            console.log("Document successfully updated!");
            this.ship.id = id
            this.oldship = this.ship
            this.reset()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        })
    },
    reset: function(){ 

        this.edited = false
        this.oldship = typeof this.oldship == 'string' ? JSON.parse(this.oldship) : this.oldship
        
        this.ship = this.oldship
        this.oldship = JSON.stringify(this.oldship)
    }
  }
}
</script>
