<template>
  <section class="dashboard d-flex align-items-center justify-content-center bg-light" style="min-height: 100vh;">
    <div class="container" style="max-width:500px;">
        <aside class="card shadow-sm">
            <header class="card-header text-center p-3">
                <h1 class="h2">Sign in now</h1>
                <p class="lead mb-0">Are you not a member? <router-link :to="{name:'Signup'}" class="text-primary">Sign up</router-link></p>

                
                <p v-if="success" class="alert alert-success text-center p-1 mt-2 mb-0">{{success}}</p>
                <p v-if="error" class="alert alert-danger text-center p-1 mt-2 mb-0">{{error}}</p>
            </header>
            <form action="" @submit.prevent="registerUser" id="fenito-register" class="card-body p-4">
                
                <fieldset class="form-group mb-2">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" placeholder="Email address" id="email" name="email" required v-model="user.email"/>
                </fieldset>
                <fieldset class="form-group mb-2">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" placeholder="Password" id="password" name="password" aria-describedby="passwordHelp" required v-model="user.password"/>
                    <small id="passwordHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                </fieldset>
                <button type="submit" class="btn btn-primary btn-block w-100">
                    <span v-if="!loading">Sign In</span>
                    <span v-else class="d-inline-block d-flex align-items-center justify-content-center">
                    <span class="spinner-border spinner-border-sm m-0 me-2" role="status" aria-hidden="true"></span>
                    Signing in...
                    </span>
                </button>
            </form>
        
        </aside>
        <p class="text-center py-3 small text-muted">Copyright &copy; Renting app 2020-2021. All rights reserved</p>
    </div>
      
  </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'

export default {
  name: 'Signin',
  components: {
  },
  data(){
    return {
      user:{
        email : null,
        password: null
      },
      errors:false,
      error:null,
      success:null,
      loading:false,
    }
  },
  methods:{
    registerUser(event){

      this.loading = true

      if (!this.user.fullName) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.user.email) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.user.password) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.errors) {

        firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
          .then((userCredential) => {
            // Signed in
            let user = userCredential.user;
            if(user){
              window.localStorage.setItem('user',user);
              this.$router.push({
                name: `Products`
              })
            }
            // ...
          })
          .catch(err => {
            console.log(err);
            this.error = err.message
          })
         
      }else{
        this.loading = true
        this.error = 'Something went wrong.'
      }
    }
  }
}
</script>
