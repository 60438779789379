<template>
  <section class="new-product bg-light d-flex align-items-center justify-content-center py-3 py-md-5 h-100" style="min-height:100vh;">
    <div v-if="team" class="container" style="max-width:500px;">
        <header class="py-3 text-center">
                <h1 class="h2">Create new team</h1>
                <p class="text-muted mb-2">Add products to your team and invite members.</p>
            </header>
        
        <form action="" @submit.prevent="createProduct" id="inventory-new-product" class="card shadow-sm">
            
            <div class="card-body p-4">

                <fieldset class="form-floating mb-3">
                    <input type="text" class="form-control" placeholder="Name" id="team-name" name="team-name" required v-model="team.name"/>
                    <label for="team-name" class="mb-1">Team name</label>
                </fieldset>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button class="btn btn-primary w-100">
                        Create Team
                    </button>
                </fieldset>
            </div>
        </form>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'

export default {
  name: 'NewTeam',
  components: {
  },
  data(){
    return {
        team:{
           name:null,
           user_id:null 
        }
    }
  },
  created(){
      if (!this.$route.params.user ) this.$router.push({name:'Signin'})
      this.reset()
  },
  methods:{
    createProduct: function(){
        db.collection('teams').add(this.team).then((doc) => {
            this.loading = false
            this.$router.push({name:'Products'})
        }).catch(err => {
            this.loading = false
            console.log(err);
        })
    },
    reset: function(){ 
        this.team = {
            name:null,
            user_id:this.$route.params.user.email 
        } 
    }
  }
}
</script>
