<template>
    <section class="new-warehouse">
    <form action="" @submit.prevent="createWarehouse" id="inventory-new-warehouse">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Products'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">New Warehouse</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary">
                        Save
                    </button>
                </fieldset>
            </div>
        </header>

        <div class="row">

            <div class="col-12 border-bottom" v-if="warehouse"> 

                <div class="row">
                    <article class="col-12 col-lg-8">


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">General information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Name" id="warehouse-name" name="warehouse-name" required v-model="warehouse.name"/>
                                    <label for="warehouse-name" class="mb-1">Name</label>
                                </fieldset>

                            </div>
                        </div>


                        <Addresses :addresses="warehouse.addresses"/>


                    </article>
                    <aside class="col-12 col-lg-4">
                        


                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Warehouse status</h3>
                            </header>
                            <div class="card-body">
                                <fieldset class="form-floating mb-3">
                                    <select id="warehouse-status" class="form-select" aria-label="Active" v-model="warehouse.status" required>
                                        <option value="Active" selected>Active</option>
                                        <option value="Retired">Retired</option>
                                    </select>
                                    <label for="warehouse-status" class="mb-1">Status</label>
                                </fieldset>
                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Contact info</h3>
                            </header>
                            <div class="card-body">
                                
                                 <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="warehouse-contact-name" name="warehouse-contact-name" v-model="warehouse.contact.name"/>
                                    <label for="warehouse-contact-name" class="mb-1">Name</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="email" class="form-control" placeholder="Type" id="warehouse-contact-email" name="warehouse-contact-email" v-model="warehouse.contact.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                                    <label for="warehouse-contact-email" class="mb-1">Email</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="warehouse-contact-phone" name="warehouse-contact-phone" v-model="warehouse.contact.phone"/>
                                    <label for="warehouse-contact-phone" class="mb-1">Phone number</label>
                                </fieldset>

                            </div>
                        </div>


                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon } from 'vue-feather-icons'
import Addresses from '@/components/addresses/Addresses.vue'

export default {
  name: 'NewWarehouse',
  components: {
      ArrowLeftIcon,
      Addresses
  },
  data(){
    return {
        warehouse:{}
    }
  },
  created(){
      this.reset()
  },
  methods:{
    createWarehouse: function(){
        db.collection('warehouses').add(this.warehouse).then((doc) => {
            this.loading = false
            this.$router.push({name:'Warehouses'})
        }).catch(err => {
            this.loading = false
            console.log(err);
        })
    },
    reset: function(){ 
        this.warehouse = {
            name:'', 
            status:'Active',
            addresses:[], 
            contact:{
                name:'',
                email:'',
                phone:''
            }
        } 
        
    }
  }
}
</script>
