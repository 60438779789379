<template>
    <div>

        <div class="card mb-3 shadow-sm">
            <header class="card-header border-0 mt-2 d-flex align-items-center justify-content-between pb-0">
                <h3 class="h5 mb-0">Products</h3>
                <div class="col-5 dropdown" @blur="showsearch = false">
                    <fieldset class="w-100">
                        <input type="text" class="form-control w-100" id="search-products" name="search-products" placeholder="Search and add products" v-model="searchvalue" @focus="showsearch = true"/>
                    </fieldset>
                    <ul class="dropdown-menu w-100" :class="{show:showsearch}" aria-labelledby="dropdownMenuButton1">
                        <li v-for="prod in filtered_products" :key="prod.id"><a class="dropdown-item" @click.prevent="newProduct(prod)">{{prod.name}}</a></li>
                    </ul>
                </div>
            </header>
            <div class="card-body px-0">
                
                <div v-if="products.length == 0" class="text-center border-top">
                    <p class="text-muted py-3 mb-0">No products found</p>
                </div>
                <div v-else>
                    <table class="table">

                            <thead>
                                <tr>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Units</th>
                                    <th scope="col">Cogs</th>
                                    <th scope="col">Total Cost</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in products" :key="product.id">
                                    <th scope="row">{{product.sku}}</th>
                                    <td>
                                        <fieldset class="">
                                            <input type="number" class="form-control" placeholder="Units" id="warehouse-adress-box" name="product-units" v-model="product.units"/>
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset class="">
                                            <input type="number" class="form-control" placeholder="Cogs" id="warehouse-adress-box" name="product-units" v-model="product.cogs"/>
                                        </fieldset>
                                    </td>
                                    <td>{{ product.units * product.cogs }}</td>
                                    <td>
                                        <button class="btn btn-outline-danger" @click="removeProduct(product)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                </div>
            </div>

            

        </div>

        
        
    </div>
    
</template>

<script>
import db from '@/firebase/init'

// @ is an alias to /src
import { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'ProductsSelector',
  components: { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon },
  props: ['products','productsearch'],
  data(){
    return{
        product: null,
        editing:false,
        showsearch:false,
        searchvalue: '',
    }
  },
  methods:{
      resetProduct: function(){
          this.product = null
          this.showsearch = false
          this.searchvalue = ''
      },
      addProduct: function(){
          this.products.push(this.product)
          this.updateProduct()
      },
      editProduct: function(product){
          this.product = product
      },
      updateProduct: function(){
          this.resetProduct()
      },
      removeProduct:function(product){
           var index = this.products.findIndex(function(o){
                return o.id === product.id;
            })
            if (index !== -1) this.products.splice(index, 1);
      },
      newProduct: function(prod){
        this.product = {
            id:prod.id,
            sku:prod.sku,
            units:0,
            cogs:0
        }
        if(!this.products.some(prod => prod.id === this.product.id)) return this.addProduct();
        this.resetProduct()
      }
  },
  computed:{
      filtered_products(){
            let prods = this.productsearch.filter(prod => {
                return (
                prod.name
                    .toLowerCase()
                    .indexOf(this.searchvalue.toLowerCase()) != -1
                );
            });

            return prods;
        }
  },
  filters:{
      formatStreet: function(product){
          return `${product.street} ${product.box}, ${product.zipcode} ${product.state} ${product.country}`
      }
  }
}
</script>