<template>
  <section class="notfound d-flex align-items-center justify-content-center w-100" style="min-height: 100vh;">
      <div class="container">
          <h1>Page not found</h1>
          <p>Oops, it looks like you got lost.</p>
          <a href="/" class="btn btn-primary">Go to dashboard</a>
      </div>
  </section>
</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>
