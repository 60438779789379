<template>
    <section class="new-manufacturer">
    <form action="" @submit.prevent="updateManufacturer" id="inventory-new-manufacturer">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Manufacturers'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">{{manufacturer.name}}</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary" :class="{'disabled':!edited}">
                        Update
                    </button>
                </fieldset>
            </div>
        </header>

        <div class="row">

            <div class="col-12 border-bottom" v-if="manufacturer"> 

                <div class="row">
                    <article class="col-12 col-lg-8">


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">General information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Name" id="manufacturer-name" name="manufacturer-name" required v-model="manufacturer.name"/>
                                    <label for="manufacturer-name" class="mb-1">Name</label>
                                </fieldset>

                            </div>
                        </div>

                        <Addresses  :addresses="manufacturer.addresses"/>    

                    </article>
                    <aside class="col-12 col-lg-4">
                        


                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Manufacturer status</h3>
                            </header>
                            <div class="card-body">
                                <fieldset class="form-floating mb-3">
                                    <select id="manufacturer-status" class="form-select" aria-label="Active" v-model="manufacturer.status" required>
                                        <option value="Active" selected>Active</option>
                                        <option value="Retired">Retired</option>
                                    </select>
                                    <label for="manufacturer-status" class="mb-1">Status</label>
                                </fieldset>
                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Contact info</h3>
                            </header>
                            <div class="card-body">
                                
                                 <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="manufacturer-contact-name" name="manufacturer-contact-name" v-model="manufacturer.contact.name"/>
                                    <label for="manufacturer-contact-name" class="mb-1">Name</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="email" class="form-control" placeholder="Type" id="manufacturer-contact-email" name="manufacturer-contact-email" v-model="manufacturer.contact.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                                    <label for="manufacturer-contact-email" class="mb-1">Email</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="manufacturer-contact-phone" name="manufacturer-contact-phone" v-model="manufacturer.contact.phone"/>
                                    <label for="manufacturer-contact-phone" class="mb-1">Phone number</label>
                                </fieldset>

                            </div>
                        </div>


                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon } from 'vue-feather-icons'
import Addresses from '@/components/addresses/Addresses.vue'

export default {
  name: 'NewManufacturer',
  components: {
      ArrowLeftIcon,
      Addresses
  },
  data(){
    return {
        oldmanufacturer:null,
        edited:false,
        manufacturer:null
    }
  },
  updated(){
      
      this.edited = (this.oldmanufacturer != JSON.stringify(this.manufacturer) )
  },
  created(){

      if(!this.$route.params.manufacturer) this.$router.push({name:'Manufacturers'})
      this.oldmanufacturer = this.$route.params.manufacturer
      this.reset()
  },
  methods:{
    updateManufacturer: function(){

        let id = this.manufacturer.id
        let updatedProduct = this.manufacturer
        delete updatedProduct.id

        db.collection("manufacturers").doc(id).update(updatedProduct)
        .then(() => {
            console.log("Document successfully updated!");
            this.manufacturer.id = id
            this.oldmanufacturer = this.manufacturer
            this.reset()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        })
    },
    reset: function(){ 

        this.edited = false
        this.oldmanufacturer = typeof this.oldmanufacturer == 'string' ? JSON.parse(this.oldmanufacturer) : this.oldmanufacturer
        
        this.manufacturer = this.oldmanufacturer
        this.oldmanufacturer = JSON.stringify(this.oldmanufacturer)
        
    }
  }
}
</script>
