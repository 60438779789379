<template>
    <div class="reports-component card shadow-sm p-0">
        <div class="card-header" v-if="report.inventory.length != 0 && editing">
            <header class=""><h3 class="h5 mb-3">Report info</h3></header>
            <div class="d-flex align-items-center justify-content-between">
                <fieldset class="form-floating mb-3 col-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Name" id="report-name" name="report-name" required v-model="report.name"/>
                    <label for="warehouse-name" class="mb-1">Name</label>
                </fieldset>
                <fieldset class="form-floating mb-3 col-12 col-md-3">
                    <input type="date" class="form-control" placeholder="Name" id="report-name" name="report-name" required v-model="report.updated" />
                    <label for="warehouse-name" class="mb-1">Report date</label>
                </fieldset>
            </div>
            
        </div>
        <div class="card-body px-0 py-0">

            <div v-if="loading" class="w-100 text-center">
                <div class="spinner-border spinner-border-sm text-muted" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            
            <div v-else>

                <div v-if="report.inventory.length == 0" class="text-center py-5">
                    <h3 class="h4">Create a new report</h3>
                    <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam beatae officiis ullam, cum nisi culpa.</p>
                    <button class="btn btn-primary" @click.prevent="$router.push({name:'NewReport'})"><plus-icon size="1x" class="me-1"></plus-icon>New Report</button>
                </div>

                <table class="table" v-else>

                    <thead>
                        <tr>
                        <th scope="col">
                            <span class="lead text-muted d-inline-block w-100">EOM Inventory</span>
                            <span>SKU</span>
                        </th>
                        <th scope="col" v-if="!showInventory"></th>
                        <th scope="col" v-else v-for="warehouse in user.warehouses" :key="`inv-${warehouse.id}`" :alt="warehouse.name">{{warehouse.name}}</th>
                        <th scope="col" class="border-end" style="text-align:right;"><button @click.prevent="showInventory = !showInventory" class="btn btn-light btn-sm me-2">
                            <chevron-left-icon size="1x" v-if="!showInventory"></chevron-left-icon>
                            <chevron-right-icon size="1x" v-else></chevron-right-icon>
                        </button>Total Inventory</th>
                        <th scope="col" v-if="!showSales"></th>
                        <th scope="col" v-else v-for="warehouse in user.warehouses" :key="`sale-${warehouse.id}`" :alt="warehouse.name">{{warehouse.name}}</th>
                        <th scope="col" style="text-align:right; ">
                            <span>
                                <span class="lead text-muted d-inline-block w-100">EOM Sales</span>
                                <span>
                                    <button @click.prevent="showSales = !showSales" class="btn btn-light btn-sm me-2">
                                        <chevron-left-icon size="1x" v-if="!showSales"></chevron-left-icon>
                                        <chevron-right-icon size="1x" v-else></chevron-right-icon>
                                    </button> 
                                    Total Sales
                                </span>
                            </span>
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in report.inventory" :key="product.id">
                            <th scope="row">{{product.sku}}</th>
                            <td v-if="!showInventory"></td>
                            <td v-else v-for="warehouse in user.warehouses" :key="`inv-${product.id}_${warehouse.id}`">
                                <span v-if="product.inventory[warehouse.id]">
                                    <fieldset class="form-floating" v-if="editing">
                                        <input type="number" class="form-control" placeholder="Type" :id="`warehouse-${product.id}-${warehouse.id}`" :name="`warehouse-${product.id}-${warehouse.id}`" v-model="product.inventory[warehouse.id].available" min="0" @keypress="isNumber($event,product.inventory[warehouse.id].available)" @blur="minInventory($event,product.inventory[warehouse.id])"/>
                                        <label :for="`warehouse-${product.id}-${warehouse.id}`" class="mb-1">Available</label>
                                    </fieldset>
                                    <span v-else>{{product.inventory[warehouse.id].available}}</span>
                                </span>
                                <span v-else class="text-muted small">null</span>
                                
                            </td>
                            <!-- <td  v-for="(inv, warehouse) in product.inventory" :key="`${product.id}_${warehouse}`">{{inv.available}}</td> -->
                            <td v-html="sum(product.inventory)" class="border-end" align="right" style="font-weight:700;"></td>
                            <td v-if="!showSales"></td>
                            <td v-else v-for="warehouse in user.warehouses" :key="`sale-${product.id}_${warehouse.id}`">
                                <span v-if="product.sales[warehouse.id]">
                                    <fieldset class="form-floating" v-if="editing">
                                        <input type="number" class="form-control" placeholder="Type" :id="`warehouse-sale-${warehouse.id}-${product.id}`" :name="`warehouse-sale-${warehouse.id}-${product.id}`" v-model="product.sales[warehouse.id].sale" min="0" @keypress="isNumber($event,product.sales[warehouse.id].sale)" @blur="minSale($event,product.sales[warehouse.id])"/>
                                        <label :for="`warehouse-sale-${warehouse.id}-${product.id}`" class="mb-1">Sold</label>
                                    </fieldset>
                                    <span v-else>{{ product.sales[warehouse.id].sale }}</span>
                                </span>
                                <span v-else class="text-muted small">null</span>
                            </td>
                            <td v-html="sumSale(product.sales)" align="right" style="font-weight:700;"></td>
                        </tr>
                    </tbody>
                    
                </table>
                
            </div>

            

            
        </div>
        <div class="card-footer"></div>
    </div>
</template>
<script>
import db from '@/firebase/init'

// @ is an alias to /src
import { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'DetailReportComponent',
  components: { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon },
  props: ['user','report','editing'],
  data(){
    return{
        loading: false,
        showInventory:false,
        showSales:false
    }
  },
  created(){
      let edit = this.$route.params.edit ? this.$route.params.edit : false
      this.editing = edit
      this.showInventory = edit
      this.showSales = edit
  },
  methods: {
    // a computed getter
    minInventory: function(evt,obj){
         if(!obj.available){
             obj.available = 0
         }
         evt.path[0].value = obj.available
    },
    minSale: function(evt,obj){
         if(!obj.sale){
             obj.sale = 0
         }
         evt.path[0].value = obj.sale
    },
    isNumber: function(evt,obj) {
        
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true
      }
    },
    sumSale: function(obj){
        console.log(obj)
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                let amt = obj[el].sale ? obj[el].sale : 0
                sum += parseFloat( amt );
            }
        }
        return sum;
    },
    sum: function(obj){
        console.log(obj)
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                let amt = obj[el].available ? obj[el].available : 0
                sum += parseFloat( amt );
            }
        }
        return sum;
    }
  }
  
}
</script>