
//import firestore from 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBxstMsc_tZDFSn1blGlCCuG3bXaeV3AgA",
    authDomain: "inventory-tool-14ca2.firebaseapp.com",
    projectId: "inventory-tool-14ca2",
    storageBucket: "inventory-tool-14ca2.appspot.com",
    messagingSenderId: "257637165981",
    appId: "1:257637165981:web:35f58c6170d0b3248c5d74",
    measurementId: "G-HY6FG4EGPF"
};

export default firebaseConfig