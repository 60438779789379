<template>
    <div class="reports-component px-5 py-2" id="exportTable">
        <div class="p-2" v-if="report.inventory.length != 0">
            <header class=""><h3 class="mb-1">{{report.name}}</h3></header>
            <p class="text-muted mb-0">{{ report.updated }}</p>
            <div class="py-1 d-flex align-center justify-content-between">
                <span class="lead text-muted d-inline-block">EOM Inventory</span>
                <span class="lead text-muted d-inline-block">EOM Sales</span>
            </div>
        </div>
        
        
        <div class="card px-0 py-0">

            <div v-if="loading" class="w-100 text-center">
                <div class="spinner-border spinner-border-sm text-muted" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            
            <div v-else>

                <div v-if="report.inventory.length == 0" class="text-center py-5">
                    <h3 class="h4">Create a new report</h3>
                    <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam beatae officiis ullam, cum nisi culpa.</p>
                    <button class="btn btn-primary" @click.prevent="$router.push({name:'NewReport'})"><plus-icon size="1x" class="me-1"></plus-icon>New Report</button>
                </div>

                <table class="table" v-else>

                    <thead>
                        <tr>
                        <th scope="col">
                            <span>SKU</span>
                        </th>
                        <th scope="col"  v-for="warehouse in user.warehouses" :key="`inv-${warehouse.id}`" :alt="warehouse.name" style="text-align:center;">{{warehouse.name}}</th>
                        <th scope="col" class="border-end" style="text-align:right;">Total</th>
                        <th scope="col" v-for="warehouse in user.warehouses" :key="`sale-${warehouse.id}`" :alt="warehouse.name"  style="text-align:center;">{{warehouse.name}}</th>
                        <th scope="col" style="text-align:right; ">
                            <span>
                                <span>Total</span>
                            </span>
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in report.inventory" :key="product.id">
                            <th scope="row">{{product.sku}}</th>
                            <td  v-for="warehouse in user.warehouses" :key="`inv-${product.id}_${warehouse.id}`"  align="center">
                                <span v-if="product.inventory[warehouse.id]">
                                    <fieldset class="form-floating" v-if="editing">
                                        <input type="number" class="form-control" placeholder="Type" :id="`warehouse-${product.id}-${warehouse.id}`" :name="`warehouse-${product.id}-${warehouse.id}`" v-model="product.inventory[warehouse.id].available" min="0" @keypress="isNumber($event,product.inventory[warehouse.id].available)" @blur="minInventory($event,product.inventory[warehouse.id])"/>
                                        <label :for="`warehouse-${product.id}-${warehouse.id}`" class="mb-1">Available</label>
                                    </fieldset>
                                    <span v-else>{{product.inventory[warehouse.id].available}}</span>
                                </span>
                                <span v-else class="text-muted small" >null</span>
                                
                            </td>
                            <!-- <td  v-for="(inv, warehouse) in product.inventory" :key="`${product.id}_${warehouse}`">{{inv.available}}</td> -->
                            <td v-html="sum(product.inventory)" class="border-end" align="right" style="font-weight:700;"></td>
                            
                            <td  v-for="warehouse in user.warehouses" :key="`sale-${product.id}_${warehouse.id}`" align="center">
                                <span v-if="product.sales[warehouse.id]">
                                    <fieldset class="form-floating" v-if="editing">
                                        <input type="number" class="form-control" placeholder="Type" :id="`warehouse-sale-${warehouse.id}-${product.id}`" :name="`warehouse-sale-${warehouse.id}-${product.id}`" v-model="product.sales[warehouse.id].sale" min="0" @keypress="isNumber($event,product.sales[warehouse.id].sale)" @blur="minSale($event,product.sales[warehouse.id])"/>
                                        <label :for="`warehouse-sale-${warehouse.id}-${product.id}`" class="mb-1">Sold</label>
                                    </fieldset>
                                    <span v-else>{{ product.sales[warehouse.id].sale }}</span>
                                </span>
                                <span v-else class="text-muted small">null</span>
                            </td>
                            <td v-html="sumSale(product.sales)" align="right" style="font-weight:700;"></td>
                        </tr>
                    </tbody>
                    
                </table>
                
            </div>
        </div>
    </div>
</template>
<script>
import db from '@/firebase/init'

// @ is an alias to /src

export default {
  name: 'ExportReportTable',
  props: ['user','report','editing'],
  data(){
    return{
        loading: false,
        showInventory:false,
        showSales:false
    }
  },
  methods: {
    
    sumSale: function(obj){
        console.log(obj)
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                let amt = obj[el].sale ? obj[el].sale : 0
                sum += parseFloat( amt );
            }
        }
        return sum;
    },
    sum: function(obj){
        console.log(obj)
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                let amt = obj[el].available ? obj[el].available : 0
                sum += parseFloat( amt );
            }
        }
        return sum;
    }
  }
  
}
</script>