<template>
    <section class="new-product">
    <form action="" @submit.prevent="createProduct" id="inventory-new-product">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Products'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">New Product</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary">
                        Save
                    </button>
                </fieldset>
            </div>
        </header>

        <div class="row">

            <div class="col-12 border-bottom" v-if="product"> 

                <div class="row">
                    <article class="col-12 col-lg-8">


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">General information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Name" id="product-name" name="product-name" required v-model="product.name"/>
                                    <label for="product-name" class="mb-1">Name</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Retail price" id="product-retail_price" name="product-retail_price" v-model="product.retail_price"/>
                                    <label for="product-retail_price" class="mb-1">Retail price</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="product-date-launched" name="product-date-launched" v-model="product.date_launched"/>
                                    <label for="product-date-launched" class="mb-1">Date launched</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-product_type" name="product-product_type" required v-model="product.product_type"/>
                                    <label for="product-product_type" class="mb-1">Product type</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <textarea class="form-control" placeholder="Notes" id="product-notes" style="height: 100px" v-model="product.notes"></textarea>
                                    <label for="product-notes">Notes</label>
                                </fieldset>

                            </div>
                        </div>


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Case information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-unites_per_case" name="product-unites_per_case" v-model="product.unites_per_case"/>
                                    <label for="product-unites_per_case" class="mb-1">Units per case</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-case_dimensions" name="product-case_dimensions" v-model="product.case_dimensions"/>
                                    <label for="product-case_dimensions" class="mb-1">Case dimensions</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-case_weight" name="product-case_weight" v-model="product.case_weight"/>
                                    <label for="product-case_weight" class="mb-1">Case weight</label>
                                </fieldset>
                                
                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-cases_per_pallet" name="product-cases_per_pallet" v-model="product.cases_per_pallet"/>
                                    <label for="product-cases_per_pallet" class="mb-1">Cases per pallet</label>
                                </fieldset>

                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Pallet information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-units_per_pallet" name="product-units_per_pallet" v-model="product.units_per_pallet"/>
                                    <label for="product-units_per_pallet" class="mb-1">Units per pallet</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-pallet_dimensions" name="product-pallet_dimensions" v-model="product.pallet_dimensions"/>
                                    <label for="product-pallet_dimensions" class="mb-1">Pallet dimensions</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-pallet_weight" name="product-pallet_weight" v-model="product.pallet_weight"/>
                                    <label for="product-pallet_weight" class="mb-1">Pallet weight</label>
                                </fieldset>

                            </div>
                        </div>


                    </article>
                    <aside class="col-12 col-lg-4">
                        


                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Product status</h3>
                            </header>
                            <div class="card-body">
                                <fieldset class="form-floating mb-3">
                                    <select id="product-status" class="form-select" aria-label="Active" v-model="product.status" required>
                                        <option value="Active" selected>Active</option>
                                        <option value="Retired">Retired</option>
                                    </select>
                                    <label for="product-status" class="mb-1">Status</label>
                                </fieldset>
                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm" v-if="product.inventory">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Inventory</h3>
                            </header>
                            <div class="card-body">
                                
                                <fieldset class="form-floating mb-3" v-for="(inv, warehouse) in product.inventory" :key="`${product.id}_${warehouse}`">
                                    <input type="number" class="form-control" placeholder="Inventory" :id="`product-inventory-${warehouse}`" :name="`product-inventory-${warehouse}`" v-model="inv.available" min="0" required />
                                    <label for="product-sku" class="mb-1">{{inv.name}}</label>
                                </fieldset>

                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Product management</h3>
                            </header>

                            <div class="card-body">
                                
                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Sku" id="product-sku" name="product-sku" required v-model="product.sku"/>
                                    <label for="product-sku" class="mb-1">Sku</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Amazon asin" id="product-amazon-asin" name="product-amazon-asin" required v-model="product.amazon_asin"/>
                                    <label for="product-amazon-asin" class="mb-1">Amazon asin</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="UPC" id="product-upc" name="product-upc" required v-model="product.upc"/>
                                    <label for="product-upc" class="mb-1">UPC</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Barcode" id="product-barcode" name="product-barcode" v-model="product.barcode"/>
                                    <label for="product-barcode" class="mb-1">Barcode</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="ISBN" id="product-isbn" name="product-isbn" v-model="product.isbn"/>
                                    <label for="product-isbn" class="mb-1">ISBN</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-avg_cogs" name="product-avg_cogs" v-model="product.avg_cogs"/>
                                    <label for="product-avg_cogs" class="mb-1">Avg cogs</label>
                                </fieldset>

                            </div>

                        </div>


                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon} from 'vue-feather-icons'

export default {
  name: 'NewProduct',
  components: {
      ArrowLeftIcon
  },
  props: ['user'],
  data(){
    return {
        warehouses:[],
        product:{
            
        }
    }
  },
  created(){
      console.log(this.user.warehouses)
      if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouse'})
      this.reset()
  },
  methods:{
      getWarehouses: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/warehouses')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.warehouses = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    createProduct: function(){
        db.collection('products').add(this.product).then((doc) => {
            this.loading = false
            this.$router.push({name:'Products'})
        }).catch(err => {
            this.loading = false
            console.log(err);
        })
    },
    reset: function(){ 
        this.product = {
            name:'', 
            status:'Active',
            date_launched:'',
            sku:'', 
            amazon_asin:'',
            upc:'', 
            barcode:'', 
            isbn:'', 
            retail_price:0,
            product_type:'', 
            unites_per_case:0, 
            case_dimensions:0, 
            case_weight:'', 
            cases_per_pallet:0, 
            units_per_pallet:0, 
            pallet_dimensions:'', 
            pallet_weight:'', 
            avg_cogs:'', 
            notes:''
        } 
        this.assignInventory()
    },
    assignInventory: function(){
        if (this.user.warehouses.length == 0) return false
        this.product.inventory = {}
        for (let i = 0; i < this.user.warehouses.length; i++){
            let warehouse = this.user.warehouses[i]
            this.product.inventory[warehouse.id] = {
                name: warehouse.name,
                available:0,
                inbound:0}
        }
    }
  }
}
</script>
