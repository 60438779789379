<template>
    <section class="new-order">
    <form action="" @submit.prevent="updateOrder" id="inventory-new-order" v-if="order" @change="edited=true">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Orders'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">{{order.po}}</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary" :class="{'disabled':!edited}">
                        Update
                    </button>
                </fieldset>
            </div>
        </header>
        <div class="row">

            <div class="col-12 border-bottom" v-if="order && manufacturers && products"> 

                <div class="row">
                    <article class="col-12 col-lg-8">


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Order information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Name" id="po-name" name="po-name" v-model="order.po" required/>
                                    <label for="po-name" class="mb-1">PO #</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-date-signed" name="po-date-signed" v-model="order.signed_date" required/>
                                    <label for="po-date-signed" class="mb-1">PO Signed Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-date-approved" name="po-date-approved" v-model="order.files_approval_date" required/>
                                    <label for="po-date-approved" class="mb-1">Files approval Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-est-date" name="po-est-date" v-model="order.est_delivery_date" required/>
                                    <label for="po-est-date" class="mb-1">Est. Delivery Date</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <select id="po-status" class="form-select" aria-label="Active" v-model="order.manufacturer" required>
                                        <option v-for="(manufacturer,index) in manufacturers" :key="manufacturer.id" :selected="{selected:(index == 0)}" :value="manufacturer.name">{{manufacturer.name}}</option>
                                    </select>
                                    <label for="po-status" class="mb-1">Manufacturer</label>
                                </fieldset>



                                <fieldset class="form-check form-group">
                                    <input type="checkbox" class="form-check-input" id="po-orderped" v-model="order.orderped">
                                    <label class="warehouse-address-default-label" for="po-orderped"><small>Shipped</small></label>
                                </fieldset>

                            </div>
                        </div>

                        <ProductSelector :products="order.skus" :productsearch="products"/>

                    </article>
                    <aside class="col-12 col-lg-4">

                        <Attachments :attachments="order.attachments"/>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Deposit</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="po-deposit-amount" name="po-deposit-amount" v-model="order.deposit.amount" required/>
                                    <label for="po-deposit-amount" class="mb-1">Deposit Amount</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-deposit-date" name="po-deposit-date" v-model="order.deposit.date" required/>
                                    <label for="po-deposit-date" class="mb-1">Deposit Date</label>
                                </fieldset>

                                <div class="form-check form-group mb-3">
                                    <input type="checkbox" class="form-check-input" id="po-deposit-paid" v-model="order.deposit.paid">
                                    <label class="warehouse-address-default-label" for="po-deposit-paid"><small>Deposit paid</small></label>
                                </div>

                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Final payment</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="po-final_payment-amount" name="po-final_payment-amount" v-model="order.final_payment.amount" required/>
                                    <label for="po-final_payment-amount" class="mb-1">Payment Amount</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="po-final_payment-date" name="po-final_payment-date" v-model="order.final_payment.date" required/>
                                    <label for="po-final_payment-date" class="mb-1">Payment Date</label>
                                </fieldset>

                                <div class="form-check form-group mb-3">
                                    <input type="checkbox" class="form-check-input" id="po-final_payment-paid" v-model="order.final_payment.paid">
                                    <label class="warehouse-address-default-label" for="po-final_payment-paid"><small>Payment paid</small></label>
                                </div>
                            </div>
                        </div>
                        
                        

                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon} from 'vue-feather-icons'
import ProductSelectorUnits from '@/components/productselect/ProductSelectorUnits.vue'
import Attachments from '@/components/productselect/Attachments.vue'
import Orders from '@/components/orders/OrderSelect.vue'

export default {
  name: 'DetailOrder',
  components: {
      ArrowLeftIcon,ProductSelectorUnits,Attachments,Orders
  },
  props: ['user'],
  data(){
    return {

        manufacturers:null,
        products:null,
        oldorder:null,
        order:null,
        edited:false,
        barcodelink:null
    }
  },
  updated(){
      
      this.edited = (this.oldorder != JSON.stringify(this.order) )
  },
  created(){

      if(!this.$route.params.order) this.$router.push({name:'Orders'})
      if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouses'})
      this.oldorder = this.$route.params.order
      this.warehouses = this.user.warehouses
      this.getManufacturers()
      this.getProducts()
      this.reset()
  },
  filters:{
      formatDate: function(date){
        return !date ? '' :  moment(date).format('MM/DD/YYYY hh:mm')
      }
  },
  methods:{
      getManufacturers: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/manufacturers')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.manufacturers = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    getOrders: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/orders')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.orders = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    getProducts: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/products')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.products = data
                
            });

            this.loading = false
            

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    },
    updateOrder: function(){

        let id = this.order.id
        let updatedOrder = this.order
        delete updatedOrder.id

        db.collection("orders").doc(id).update(updatedOrder)
        .then(() => {
            console.log("Document successfully updated!");
            this.order.id = id
            this.oldorder = this.order
            this.reset()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        })
    },
    reset: function(){ 

        this.edited = false
        this.oldorder = typeof this.oldorder == 'string' ? JSON.parse(this.oldorder) : this.oldorder
        
        this.order = this.oldorder
        this.oldorder = JSON.stringify(this.oldorder)
    }
  }
}
</script>
