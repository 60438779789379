<template>

  <section class="products">

    <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <h2 class="h3 m-0">Products</h2>
        </div>
        
        <div>
            <fieldset class="form-group d-flex align-items-center justify-content-between">
                <button class="btn btn-primary" @click.prevent="$router.push({name:'NewProduct'})">
                    New product
                </button>
            </fieldset>
        </div>
    </header>

    <div class="row">

       <Products v-if="user" :user="user" class="col-12 py-3 border-bottom" /> 

    </div>
    

  </section>
</template>

<script>
// @ is an alias to /src
import db from '@/firebase/init'
import { PlusIcon } from 'vue-feather-icons'

import Products from '@/components/product/Products.vue'


import moment from 'moment'

export default {
  name: 'ProductsOverview',
  components: {
    moment,PlusIcon,Products
  },
  props: ['user'],
  data(){
    return{
    }
  },
  mounted(){
   
  },
  created(){
    
  }
  
}
</script>
