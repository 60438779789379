<template>
  <main class="admin bg-light">
    <div v-if="!loading && user" class="row mx-0 px-0 h-100">
      <Topbar v-if="user" @newproject="newproject = true" :user="user" class="col-12"/>
      <!--<Sidebar v-if="user" class="col-10 col-md-3" style="min-height: 100vh; max-height: 100vh; max-width:325px;" @newproject="newproject = true" :user="user"/> -->
      <!--<Navigation/> -->
      <div class="col px-0">
        <!-- <Topbar/>-->
        <div class="container-fluid px-md-5"> 
          <router-view class="py-2" :user="user" v-if="user.warehouses"></router-view>
        </div>
      </div>
    </div>
    <div v-else class="fixed-top w-100 h-100 bg-light d-flex align-items-center justify-content-center ">
      <div>
        <div class="spinner-border text-dark" role="status">
          <span class="visually-hidden text-xl font-medium text-black">Loading...</span>
        </div>
      </div>
      
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'
import db from '@/firebase/init'

//import Sidebar from '@/components/navigation/Sidebar.vue'
import Topbar from '@/components/navigation/Topbar.vue'

export default {
  name: 'Admin',
  components: {Topbar
    //Navigation,
    //Topbar
  },
  data(){
    return{
      loading: true,
      user:null,
      newproject: false
    }
  },
  methods:{
    loadWarehouses(){
     
      db.collection("warehouses").get()
      .then((querySnapshot) => {

        this.user.warehouses = []

        querySnapshot.forEach((doc) => {
          
            let warehouse = doc.data()
            warehouse.id = doc.id
            this.user.warehouses.push(warehouse)
            console.log(this.user)
        })

        self.loading = false
        
        if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouse',params:{user:this.user}}) 
        

          
      }).then(()=>{this.loading=false})
      .catch((error) => {
          console.log("Error getting documents: ", error);
      })
      
    },
    closeProject(){
      this.newproject = false
      //this.loadProjects()
    }
  },
  created(){
    let self = this
    
    if(!this.user){
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          let ref = db.collection('users').doc(user.email)
          ref.get().then(doc => {
            if (doc.exists) {
              self.user = doc.data()
              self.user.email = user.email
              window.localStorage.setItem('user',self.user)
              self.loadWarehouses()
            } else {
              self.$router.push({ name: 'Signup' })
            }
          })
        } else {
          self.$router.push({ name: 'Signin' })
        }
      })
    }else{
      self.loadWarehouses()
      console.log('USER IS SIGNED IN')
    }
  }
}
</script>
<style lang="scss">
  .admin{
    min-height: 100vh;
  }
  @media screen and (max-width: 992px) {
    .side-navigation {
      position: fixed;
      z-index: 9999;
      height: 100vh;
      overflow-y:scroll;
      width: 90%;
      top: 0;
      left: -100%;
    }
  }
</style>
