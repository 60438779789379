import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'

import Admin from '../views/Admin.vue'

//404
import PageNotFound from '../views/notfound/PageNotFound.vue'

//DASHBOARD
import Dashboard from '../views/admin/dashboard/Dashboard.vue'

//MANUFACTURER
import Manufacturers from '../views/admin/manufacturer/Manufacturers.vue'
import DetailManufacturer from '../views/admin/manufacturer/Detail.vue'
import NewManufacturer from '../views/admin/manufacturer/New.vue'

//ORDERS
import NewShipment from '../views/admin/shipping/NewShipment.vue'
import Shipment from '../views/admin/shipping/Detail.vue'
import Shipments from '../views/admin/shipping/Shipments.vue'

//ORDERS
import Orders from '../views/admin/orders/Orders.vue'
import Order from '../views/admin/orders/Detail.vue'
import NewOrder from '../views/admin/orders/NewOrder.vue'

//REPORTS
import Reports from '../views/admin/reports/Reports.vue'
import DetailReport from '../views/admin/reports/Details.vue'
import NewReport from '../views/admin/reports/New.vue'

//Warehouses
import Warehouses from '../views/admin/warehouses/Warehouses.vue'
import DetailWarehouse from '../views/admin/warehouses/Detail.vue'
import NewWarehouse from '../views/admin/warehouses/New.vue'

//PRODUCT
import Products from '../views/admin/products/Products.vue'
import DetailProduct from '../views/admin/products/Detail.vue'
import NewProduct from '../views/admin/products/New.vue'
import NewTeam from '../views/admin/teams/New.vue'

//AUTH
import Signup from '../views/auth/Signup.vue'
import Signin from '../views/auth/Signin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      requiresAuth: true
    },
    component: Admin,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        meta: { title: 'Dashboard - BestSelf Co.' },
        component: Dashboard
      },
      {
        path: '/manufacturers',
        name: 'Manufacturers',
        meta: { title: 'Manufacturers - BestSelf Co.' },
        component: Manufacturers
        
      },
      {
        path: '/manufacturer/:id',
        name: 'Manufacturer',
        meta: { title: 'Manufacturer - BestSelf Co.' },
        component: DetailManufacturer
      },
      {
        path: '/manufacturers/new',
        name: 'NewManufacturer',
        meta: { title: 'New Manufacturers - BestSelf Co.' },
        component: NewManufacturer
      },
      {
        path: '/shipments',
        name: 'Shipments',
        meta: { title: 'Shipments - BestSelf Co.' },
        component: Shipments
      },
      {
        path: '/shipment/:id',
        name: 'Shipment',
        meta: { title: 'Shipment - BestSelf Co.' },
        component: Shipment
      },
      {
        path: '/shipments/new',
        name: 'NewShipment',
        meta: { title: 'New Shipment - BestSelf Co.' },
        component: NewShipment
      },
      {
        path: '/orders',
        name: 'Orders',
        meta: { title: 'Orders - BestSelf Co.' },
        component: Orders
      },
      {
        path: '/order/:id',
        name: 'Order',
        meta: { title: 'Order - BestSelf Co.' },
        component: Order
      },
      {
        path: '/orders/new',
        name: 'NewOrder',
        meta: { title: 'New Order - BestSelf Co.' },
        component: NewOrder
      },
      {
        path: '/reports',
        name: 'Reports',
        meta: { title: 'Reports - BestSelf Co.' },
        component: Reports
      },
      {
        path: '/report/:id',
        name: 'Report',
        meta: { title: 'New Report - BestSelf Co.' },
        component: DetailReport
      },
      {
        path: '/reports/new',
        name: 'NewReport',
        meta: { title: 'New Report - BestSelf Co.' },
        component: NewReport
      },
      {
        path: '/products',
        name: 'Products',
        meta: { title: 'Products - BestSelf Co.' },
        component: Products
      },
      {
        path: '/product/:id',
        name: 'Product',
        meta: { title: 'Product - BestSelf Co.' },
        component: DetailProduct
      },
      {
        path: '/products/new',
        name: 'NewProduct',
        meta: { title: 'New product - BestSelf Co.' },
        component: NewProduct
      },
      {
        path: '/warehouses/new',
        name: 'NewWarehouse',
        meta: { title: 'New Warehouse - BestSelf Co.' },
        component: NewWarehouse
      },
      {
        path: '/warehouse/:id',
        name: 'Warehouse',
        meta: { title: 'Warehouse - BestSelf Co.' },
        component: DetailWarehouse
      },
      {
        path: '/warehouses',
        name: 'Warehouses',
        meta: { title: 'Warehouses - BestSelf Co.' },
        component: Warehouses
      }
    ]
  },
  {
    path: '/auth/sign-up',
    name: 'Signup',
    meta: { title: 'Sign Up - BestSelf Co.' },
    component: Signup,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/auth/sign-in',
    name: 'Signin',
    meta: { title: 'Sign In - BestSelf Co.' },
    component: Signin,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/team/new',
    name: 'NewTeam',
    meta: { title: 'New team' },
    component: NewTeam,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/*',
    name: 'PageNotFound',
    meta: { title: 'Page not found - BestSelf Co.' },
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(isAuthenticated());
    if (!isAuthenticated()) {
       next({name:'Signin'})
    }
  } else {
     next()
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    .forEach(tag => document.head.appendChild(tag));

  next();
});

const isAuthenticated = () =>{
  return window.localStorage.getItem('user');
}

export default router