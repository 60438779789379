<template>
    <div class="dashboard-component card shadow-sm">


        <div class="card-header"></div>
        <div class="card-body px-0">

            <table class="table table-hover" v-if="inventory">
                <thead>
                    <tr>
                    <th scope="col">SKU</th>
                    <th scope="col">Status</th>
                    <th scope="col" v-for="warehouse in inventory.warehouses" :key="warehouse.id">{{warehouse.name}}</th>
                    <th scope="col">Total Inventory</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in inventory.products" :key="product.id">
                        <th scope="row">{{product.sku}}</th>
                        <td>
                            <div class="nav-item dropdown">
                                <a class="btn btn-sm btn-success" href="#" :id="`propertyDropDown${product.id}`" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{product.status}}
                                </a>
                                <ul class="dropdown-menu position-absolute dropdown-menu-end shadow-sm" :aria-labelledby="`propertyDropDown${product.id}`" style="max-width:200px;">
                                    <li><a class="dropdown-item" href="#">Active</a></li>
                                    <li><a class="dropdown-item" href="#">Retired</a></li>
                                    <li><hr class="dropdown-divider"></li>
                                    <li><a class="dropdown-item text-danger" href="#" @click.prevent="removeProduct(product)">Archive</a></li>
                                </ul>
                            </div>
                        </td>
                        <td v-for="warehouse in inventory.warehouses" :key="`${product.id}_${warehouse.id}`">{{product.inventory[warehouse.id].available}}</td>
                        <!-- <td  v-for="(inv, warehouse) in product.inventory" :key="`${product.id}_${warehouse}`">{{inv.available}}</td> -->
                        <td>{{product.total}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer"></div>
    </div>
</template>
<script>
export default {
  name: 'DashboardComponent',
  components: {},
  props: ['user'],
  data(){
    return{
        inventory: null,
        loading:true
    }
  },
  created(){
    this.getInventory()
  },
  methods: {
    // a computed getter
    getInventory: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/inventory')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.inventory = data
            });

            this.loading = false

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    }
  }
  
}
</script>