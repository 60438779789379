<template>

  <section class="products">

    <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <button  @click.prevent="$router.push({name:'Reports'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                <arrow-left-icon size="1.2x"></arrow-left-icon>
            </button>
            <h2 class="h3 m-0">New Report</h2>
        </div>
        
        <div>
            <fieldset class="form-group d-flex align-items-center justify-content-between">
                <button class="btn btn-primary" :class="{disabled:creating}" @click.prevent="createReport">
                    Create
                </button>
            </fieldset>
        </div>
    </header>

    <div class="row">

       <NewReports class="col-12 py-3 border-bottom" :user="user" :report="report"/> 

    </div>
    

  </section>
</template>

<script>
// @ is an alias to /src
import db from '@/firebase/init'
import { ArrowLeftIcon } from 'vue-feather-icons'

import NewReports from '@/components/reports/NewReport.vue'


import moment from 'moment'

export default {
  name: 'NewReport',
  components: {
    moment,ArrowLeftIcon,NewReports
  },
  props: ['user'],
  data(){
    return{
        creating: false,
        report:{
            name: '',
            product_id:'',
            inventory:null,
            updated: new Date(),
        }
    }
  },
  mounted(){
   
  },
  created(){
    this.report.name = `Report-${moment().format('MM/DD/YYYY')}`
    this.report.user = this.user.email
    
  },
  methods:{
    createReport: function(){
        this.creating = true
        let batch = db.batch();

        console.log(this.report)

        this.report.updated = this.report.updated.length == 0 ? moment().format() : this.report.updated
        console.log(this.report)


        for (var i = 0; i < this.report.inventory.length; i++){
            let inv = this.report.inventory[i]
            inv.updated = moment().format()
            inv.user = this.report.user
            console.log(inv)
            let newInv = db.collection("inventory").doc()
            batch.set(newInv,inv)
        }

        batch.commit().then((res) => {
            console.log('Updated Inventory:',res)
            db.collection('reports').add(this.report).then((doc) => {
                this.creating = false
                this.$router.push({name:'Reports'})
            }).catch(err => {
                this.creating = false
                console.log(err);
            })
        })

    }
  }
  
}
</script>
