<template>
    <section class="new-product">
    <form action="" @submit.prevent="updateProduct" id="inventory-new-product" v-if="product" @change="edited=true">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Products'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <h2 class="h3 m-0">{{product.name}}</h2>
            </div>
            
            <div>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Discard</button>
                    <button class="btn btn-primary" :class="{'disabled':!edited}">
                        Update
                    </button>
                </fieldset>
            </div>
        </header>
        <div class="row">

            <div class="col-12 border-bottom"> 

                <div class="row">
                    <article class="col-12 col-lg-8">

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">General information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Name" id="product-name" name="product-name" required v-model="product.name"/>
                                    <label for="product-name" class="mb-1">Name</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Retail price" id="product-retail_price" name="product-retail_price" v-model="product.retail_price"/>
                                    <label for="product-retail_price" class="mb-1">Retail price</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="date" class="form-control" placeholder="Date" id="product-date-launched" name="product-date-launched" v-model="product.date_launched"/>
                                    <label for="product-date-launched" class="mb-1">Date launched</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-product_type" name="product-product_type" required v-model="product.product_type"/>
                                    <label for="product-product_type" class="mb-1">Product type</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <textarea class="form-control" placeholder="Notes" id="product-notes" style="height: 100px" v-model="product.notes"></textarea>
                                    <label for="product-notes">Notes</label>
                                </fieldset>

                            </div>
                        </div>


                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Case information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-unites_per_case" name="product-unites_per_case" v-model="product.unites_per_case"/>
                                    <label for="product-unites_per_case" class="mb-1">Units per case</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-case_dimensions" name="product-case_dimensions" v-model="product.case_dimensions"/>
                                    <label for="product-case_dimensions" class="mb-1">Case dimensions</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-case_weight" name="product-case_weight" v-model="product.case_weight"/>
                                    <label for="product-case_weight" class="mb-1">Case weight</label>
                                </fieldset>
                                
                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-cases_per_pallet" name="product-cases_per_pallet" v-model="product.cases_per_pallet"/>
                                    <label for="product-cases_per_pallet" class="mb-1">Cases per pallet</label>
                                </fieldset>

                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">
                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Pallet information</h3>
                            </header>
                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="number" class="form-control" placeholder="Type" id="product-units_per_pallet" name="product-units_per_pallet" v-model="product.units_per_pallet"/>
                                    <label for="product-units_per_pallet" class="mb-1">Units per pallet</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-pallet_dimensions" name="product-pallet_dimensions" v-model="product.pallet_dimensions"/>
                                    <label for="product-pallet_dimensions" class="mb-1">Pallet dimensions</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-pallet_weight" name="product-pallet_weight" v-model="product.pallet_weight"/>
                                    <label for="product-pallet_weight" class="mb-1">Pallet weight</label>
                                </fieldset>

                            </div>
                        </div>


                    </article>
                    <aside class="col-12 col-lg-4">
                        


                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Product status</h3>
                            </header>
                            <div class="card-body">
                                <fieldset class="form-floating mb-3">
                                    <select id="product-status" class="form-select" aria-label="Active" v-model="product.status" required>
                                        <option value="Active" selected>Active</option>
                                        <option value="Retired">Retired</option>
                                    </select>
                                    <label for="product-status" class="mb-1">Status</label>
                                </fieldset>
                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-1">Available EOM Inventory</h3>
                                <p class="text-muted small mb-0">Updated - {{ product.updated | formatDate }} - {{ product.user_updated }}</p>
                            </header>
                            <div class="card-body">
                                
                                <fieldset class="form-floating mb-3" v-for="warehouse in user.warehouses" :key="`${product.id}_${warehouse.id}`">
                                    <input type="text" class="form-control" placeholder="Sku" id="product-sku" name="product-sku" required v-model="product.inventory[warehouse.id].available"/>
                                    <label for="product-sku" class="mb-1">{{warehouse.name}}</label>
                                </fieldset>

                            </div>
                            <div class="card-footer py-3 text-center">
                                <a href="" class="link w-100 ">Show Inventory History</a>
                            </div>
                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Barcode</h3>
                            </header>

                            <div class="card-body">

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="UPC" id="product-upc" name="product-upc" required v-model="product.upc"/>
                                    <label for="product-upc" class="mb-1">UPC</label>
                                </fieldset>

                                <div class="mx-auto d-none" :class="{'d-block':barcodelink}">

                                    <VueBarcode v-bind:value="product.upc"  class="barcode-holder w-100" style="width: 100%; margin: 0 auto;">
                                        Can't generate the barcode
                                    </VueBarcode>
                                </div>
                                <button class="btn btn-primary w-100" @click.prevent="downloadBarcode" v-if="!barcodelink">Generate Barcode</button>

                                <a class="btn btn-primary w-100" v-else :href="barcodelink">Download Barcode</a>

                            </div>

                        </div>

                        <div class="card mb-3 shadow-sm">

                            <header class="card-header border-0 mt-2">
                                <h3 class="h5 mb-0">Product management</h3>
                            </header>

                            <div class="card-body">
                                
                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Sku" id="product-sku" name="product-sku" required v-model="product.sku"/>
                                    <label for="product-sku" class="mb-1">Sku</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Amazon asin" id="product-amazon-asin" name="product-amazon-asin" required v-model="product.amazon_asin"/>
                                    <label for="product-amazon-asin" class="mb-1">Amazon asin</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="ISBN" id="product-isbn" name="product-isbn" v-model="product.isbn"/>
                                    <label for="product-isbn" class="mb-1">ISBN</label>
                                </fieldset>

                                <fieldset class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Type" id="product-avg_cogs" name="product-avg_cogs" v-model="product.avg_cogs"/>
                                    <label for="product-avg_cogs" class="mb-1">Avg cogs</label>
                                </fieldset>

                            </div>

                        </div>


                    </aside>
                </div>
                    
            </div>

        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon} from 'vue-feather-icons'
import moment from 'moment'
import VueBarcode from 'vue-barcode';

export default {
  name: 'DetailProduct',
  components: {
      ArrowLeftIcon,
      VueBarcode
  },
  props: ['user'],
  data(){
    return {
        oldproduct:null,
        product:null,
        edited:false,
        barcodelink:null
    }
  },
  updated(){
      
      this.edited = (this.oldproduct != JSON.stringify(this.product) )
  },
  created(){

      if(!this.$route.params.product) this.$router.push({name:'Products'})
      if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouses'})
      this.oldproduct = this.$route.params.product
      this.reset()
  },
  filters:{
      formatDate: function(date){
        return !date ? '' :  moment(date).format('MM/DD/YYYY hh:mm')
      }
  },
  methods:{
      downloadBarcode:function(){
          //get svg element.
          
        let svg = document.getElementsByClassName("vue-barcode-element");
        
        //get svg source.
        let serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg[0]);

        console.log(source)
        //add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        let url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
       // window.location.href = url
        //this.barcodelink = url;

        var win = window.open();
        win.document.write(`<iframe src="${url}"><\/iframe>`);
      },
    updateProduct: function(){

        let id = this.product.id
        let updatedProduct = this.product
        delete updatedProduct.id

        db.collection("products").doc(id).update(updatedProduct)
        .then(() => {
            console.log("Document successfully updated!");
            this.product.id = id
            this.oldproduct = this.product
            this.reset()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        })
    },
    reset: function(){ 

        this.edited = false
        this.oldproduct = typeof this.oldproduct == 'string' ? JSON.parse(this.oldproduct) : this.oldproduct
        
        this.product = this.oldproduct
        this.oldproduct = JSON.stringify(this.oldproduct)
    }
  }
}
</script>

<style lang="scss">
    .vue-barcode-element{
            margin: 0 auto !important;
            width: 100% !important;
    }
</style>
