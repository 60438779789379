<template>
  <section class="dashboard">
    <header class="py-3 py-md-4">
      <h2 class="h3 m-0">Inventory</h2>
    </header>

    <div v-if="!loading">
         <Dashboard /> 
    </div>
    
    <div v-if="loading" class="w-100 text-center">
      <div class="spinner-border spinner-border-sm text-muted" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
    </div>


  </section>
</template>

<script>
// @ is an alias to /src
import { PlusIcon } from 'vue-feather-icons'

import Dashboard from '@/components/dashboard/Dashboard.vue'

import moment from 'moment'

export default {
  name: 'Dash',
  components: {
    moment,PlusIcon,Dashboard
  },
  props: ['user'],
  data(){
    return{
      loading:false
    }
  },
  mounted(){
  },
  created(){
    //this.getProducts()
  },
  methods:{
    
  }
  
}
</script>
