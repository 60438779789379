<template>
    <div class="dashboard-component card shadow-sm p-0">
        <div class="card-header"></div>
        <div class="card-body px-0">

            <div v-if="loading" class="w-100 text-center">
                <div class="spinner-border spinner-border-sm text-muted" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            
            <div v-else>

                <div v-if="manufacturers.length == 0" class="text-center py-5">
                    <h3 class="h4">Add a Manufacturer</h3>
                    <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam beatae officiis ullam, cum nisi culpa.</p>
                    <button class="btn btn-outline-primary" @click.prevent="$router.push({name:'NewManufacturer'})"><plus-icon size="1x" class="me-1"></plus-icon>New Manufacturer</button>
                </div>

                <table class="table table-hover" v-else>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">ID</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr v-for="manufacturer in manufacturers" :key="manufacturer.id">
                            <th scope="row"><a :href="`/manufacturer/${manufacturer.id}`" @click.prevent="$router.push({name:'Manufacturer',params:{id:manufacturer.id,manufacturer:manufacturer}})">{{manufacturer.name}}</a></th>
                            <td>{{manufacturer.id}}</td>
                            <td align="right">
                                <div class="nav-item dropdown">
                                    <a class="btn btn-outline-secondary btn-sm bg-white text-dark px-1" href="#" :id="`propertyDropDown${manufacturer.id}`" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <more-vertical-icon size="1x"></more-vertical-icon>
                                    </a>
                                    <ul class="dropdown-menu position-absolute dropdown-menu-end shadow-sm" :aria-labelledby="`propertyDropDown${manufacturer.id}`">
                                        <li><a class="dropdown-item" href="#">Edit</a></li>
                                        <li><hr class="dropdown-divider"></li>
                                        <li><a class="dropdown-item text-danger" href="#" @click.prevent="removeManufacturer(manufacturer)">Remove</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                
            </div>

            

            
        </div>
        <div class="card-footer"></div>
    </div>
</template>
<script>
import db from '@/firebase/init'

// @ is an alias to /src
import { PlusIcon, ArrowLeftIcon,SettingsIcon,MoreVerticalIcon } from 'vue-feather-icons'

export default {
  name: 'Manufacturers',
  components: { MoreVerticalIcon },
  props: ['user'],
  data(){
    return{
        manufacturers: [],
        loading: true
    }
  },
  created(){
    this.getManufacturers()
  },
  methods: {
    // a computed getter
    removeManufacturer: function(manufacturer){
        db.collection("manufacturers").doc(manufacturer.id).delete().then(() => {
            var index = this.manufacturers.findIndex(function(o){
                return o.id === manufacturer.id;
            })
            if (index !== -1) this.manufacturers.splice(index, 1);
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    },
    getManufacturers: function(){

        fetch('https://bestself-inventory-api.herokuapp.com/manufacturers')
        .then( response =>{

            let self = this

            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                return;
            }

            // Examine the text in the response
            response.json().then(function(data) {
                console.log(data);
                self.manufacturers = data
            });

            this.loading = false

        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    }
  }
  
}
</script>