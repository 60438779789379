<template>
    <div>

        <div class="card mb-3 shadow-sm">
            <header class="card-header border-0 mt-2 d-flex align-items-center justify-content-between pb-0">
                <h3 class="h5 mb-0">Addresses</h3>
                <button class="btn btn-sm btn-outline-primary" @click.prevent="newAddress"><plus-icon size="1x" class="me-1"></plus-icon>New Address</button>
            </header>
            <div class="card-body">
                <div v-if="addresses.length == 0" class="text-center border-top">
                    <p class="text-muted py-3 mb-0">No addresses found</p>
                </div>
                <div v-else>
                    <div v-for="address in addresses" :key="address.street" class="py-2 py-md-3 d-flex align-items-center justify-content-between border-top">
                        <div class="">
                            <span>{{ address | formatStreet }}</span>
                            <span v-if="address.default" class="ms-2 badge bg-primary">Default</span>
                        </div>
                        
                        <div class="nav-item dropdown">
                            <a class="btn btn-outline-secondary btn-sm bg-white text-dark px-1" href="#" :id="`propertyDropDown${address.street}`" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <more-vertical-icon size="1x"></more-vertical-icon>
                            </a>
                            <ul class="dropdown-menu position-absolute dropdown-menu-end shadow-sm" :aria-labelledby="`propertyDropDown${address.street}`">
                                <li><a class="dropdown-item" href="#" @click.prevent="editAddress(address)">Edit</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item text-danger" href="#" @click.prevent="removeAddress(address)">Remove</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light mx-3" v-if="address">
                <div class="card-body">

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-street" name="warehouse-adress-street" v-model="address.street"/>
                        <label for="warehouse-adress-street" class="mb-1">Address line 1</label>
                    </fieldset>

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-box" name="warehouse-adress-box" v-model="address.box"/>
                        <label for="warehouse-adress-box" class="mb-1">Address line 2</label>
                    </fieldset>

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-zipcode" name="warehouse-adress-zipcode" v-model="address.zipcode"/>
                        <label for="warehouse-adress-zipcode" class="mb-1">Zipcode</label>
                    </fieldset>

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-city" name="warehouse-adress-city" v-model="address.city"/>
                        <label for="warehouse-adress-city" class="mb-1">City</label>
                    </fieldset>
                    
                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-state" name="warehouse-adress-state" v-model="address.state"/>
                        <label for="warehouse-adress-state" class="mb-1">State/province</label>
                    </fieldset>

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="warehouse-adress-country" name="warehouse-adress-country" v-model="address.country"/>
                        <label for="warehouse-adress-country" class="mb-1">Country</label>
                    </fieldset>
                    <div class="form-check form-group mb-3">
                        <input type="checkbox" class="form-check-input" id="warehouse-address-default" v-model="address.default">
                        <label class="warehouse-address-default-label" for="warehouse-address-default"><small>Default address</small></label>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <button @click.prevent="resetAddress" class="btn border bg-white me-2">Cancel</button>
                        <button v-if="!editing" @click.prevent="addAddress" class="btn btn-primary">
                           Add address
                        </button>
                        <button v-else @click.prevent="updateAddress" class="btn btn-primary">
                           Update address
                        </button>
                    </div>
                    
                </div>
            </div>

        </div>

        
        
    </div>
    
</template>

<script>
import db from '@/firebase/init'

// @ is an alias to /src
import { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'Addresses',
  components: { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon },
  props: ['addresses'],
  data(){
    return{
        address: null,
        editing:false
    }
  },
  methods:{
      resetAddress: function(){
          this.address = null
          this.editing = false
      },
      addAddress: function(){
          this.address.id= Math.floor(Math.random() * 100)
          this.addresses.push(this.address)
          this.updateAddress()
      },
      editAddress: function(address){
          this.address = address
          this.editing = true
      },
      updateAddress: function(){
          
          if(this.address.default == true){
              console.log(this.address.default)
              
              for(let i = 0; i < this.addresses.length; i++){
                  let a = this.addresses[i]
                  if(a.id !== this.address.id){
                      a.default = false
                  }
              }
          }
          this.resetAddress()
      },
      removeAddress:function(address){
           var index = this.addresses.findIndex(function(o){
                return o.id === address.id;
            })
            if (index !== -1) this.addresses.splice(index, 1);
      },
      newAddress: function(){
          this.address = {
                street:'',
                box:'',
                zipcode:'',
                city: '',
                state: '',
                country: '',
                default:false
            }
      }
  },
  filters:{
      formatStreet: function(address){
          return `${address.street} ${address.box}, ${address.zipcode} ${address.state} ${address.country}`
      }
  }
}
</script>