<template>
    <div>

        <div class="card mb-3 shadow-sm">
            <header class="card-header border-0 mt-2 d-flex align-items-center justify-content-between pb-0">
                <h3 class="h5 mb-0">Attachments</h3>
                <button class="btn btn-sm btn-outline-primary" @click.prevent="newAttachment"><plus-icon size="1x" class="me-1"></plus-icon>New Attachment</button>
            </header>
            <div class="card-body">
                <div v-if="attachments.length == 0" class="text-center border-top">
                    <p class="text-muted py-3 mb-0">No attachments found</p>
                </div>
                <div v-else>
                    <div v-for="attachment in attachments" :key="attachment.street" class="py-2 py-md-3 d-flex align-items-center justify-content-between border-top">
                        <div class="col-10">
                            <a :href="attachment.link" target="_blank" class="w-100">
                                <span class="d-inline-block text-truncate" style="max-width: 100%">
                                {{ attachment.name }}
                                </span>
                            </a>
                        </div>
                        
                        <div class="nav-item dropdown">
                            <a class="btn btn-outline-secondary btn-sm bg-white text-dark px-1" href="#" :id="`propertyDropDown${attachment.street}`" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <more-vertical-icon size="1x"></more-vertical-icon>
                            </a>
                            <ul class="dropdown-menu position-absolute dropdown-menu-end shadow-sm" :aria-labelledby="`propertyDropDown${attachment.street}`">
                                <li><a class="dropdown-item text-danger" href="#" @click.prevent="removeAttachment(attachment)">Remove</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light mx-3" v-if="attachment">
                <div class="card-body">

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="attachment-name" name="attachment-name" @keypress="$refs.file.value=null;" v-model="attachment.name"/>
                        <label for="attachment-name" class="mb-1">name</label>
                    </fieldset>

                    <fieldset class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Type" id="attachment-link" name="attachment-link" @keypress="$refs.file.value=null;" v-model="attachment.link"/>
                        <label for="attachment-link" class="mb-1">Link</label>
                    </fieldset>

                    <!-- <fieldset class="mb-3">
                        <label for="formFile" class="form-label">Or upload a file</label>
                        <input class="form-control" type="file" ref="file" id="formFile" v-on:change="handleFileUpload()">
                    </fieldset>-->

                    <div class="d-flex justify-content-end align-items-center">
                        <button @click.prevent="resetAttachment" class="btn border bg-white me-2">Cancel</button>
                        <button @click.prevent="addAttachment" class="btn btn-primary w-100" :class="{disabled:uploading}">
                           Add attachment
                        </button>
                    </div>
                    
                </div>
            </div>

        </div>

        
        
    </div>
    
</template>

<script>
import firebase from 'firebase'
import { getStorage, ref, uploadBytes } from "firebase/storage";

// @ is an alias to /src
import { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'Attachments',
  components: { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon },
  props: ['attachments'],
  data(){
    return{
        attachment: null,
        uploading:false,
        storageRef:null
    }
  },
  created(){
      this.storageRef = firebase.storage().ref();
  },
  methods:{
      resetAttachment: function(){
          this.attachment = null
          this.editing = false
      },
      handleFileUpload: function(){
          this.attachment.link = ''
          this.attachment.file = this.$refs.file.files[0];
          console.log(this.attachment.file)
      },
      addAttachment: function(){
          let self = this

          if(!this.attachment.file && this.attachment.link.length == 0) return false

          if(this.attachment.file && this.attachment.link.length == 0){
              
              let thisRef = this.storageRef.child(`${this.attachment.file.name}`)
              thisRef.put(this.attachment.file).then(function(snapshot) {
                    console.log(snapshot)
                    self.attachment.file = {
                        path: snapshot.ref.fullPath,
                        bucket: snapshot.ref.bucket
                    }
                    self.attachment.link = `https://firebasestorage.googleapis.com/b/${self.attachment.file.bucket}/o/${self.attachment.file.path}`
                    console.log(self.attachment.file)
                    self.attachments.push(self.attachment) 
                    self.resetAttachment()
                }).catch((error) => {
                    console.log(error)
                })

          }else{
              this.attachments.push(this.attachment) 
              this.resetAttachment()
          }
          

          
      },
      editAttachment: function(attachment){
          this.attachment = attachment
          this.editing = true
      },
      updateAttachment: function(){
          
          if(this.attachment.default == true){
              console.log(this.attachment.default)
              
              for(let i = 0; i < this.attachments.length; i++){
                  let a = this.attachments[i]
                  if(a.id !== this.attachment.id){
                      a.default = false
                  }
              }
          }
          this.resetAttachment()
      },
      removeAttachment:function(attachment){
           var index = this.attachments.findIndex(function(o){
                return o.id === attachment.id;
            })
            if (index !== -1) this.attachments.splice(index, 1);
      },
      newAttachment: function(){
        this.attachment = {
            link:'',
            name:'',
            file:null,
            file_id:null,
        }
      }
  }
}
</script>