<template>
    <div>

        <div class="card mb-3 shadow-sm">
            <header class="card-header border-0 mt-2 d-flex align-items-center justify-content-between pb-0">
                <h3 class="h5 mb-0">Orders</h3>
                <div class="col-5 dropdown" @blur="showsearch = false">
                    <fieldset class="w-100">
                        <input type="text" class="form-control w-100" id="search-orders" name="search-orders" placeholder="Search and add orders" v-model="searchvalue" @focus="showsearch = true"/>
                    </fieldset>
                    <ul class="dropdown-menu w-100" :class="{show:showsearch}" aria-labelledby="dropdownMenuButton1">
                        <li v-for="ord in filtered_orders" :key="ord.id"><a class="dropdown-item" @click.prevent="newOrder(ord)">{{ord.po}}</a></li>
                    </ul>
                </div>
            </header>
            <div class="card-body px-0">
                
                <div v-if="orders.length == 0" class="text-center border-top">
                    <p class="text-muted py-3 mb-0">No orders found</p>
                </div>
                <div v-else>
                    <table class="table">

                            <thead>
                                <tr>
                                    <th scope="col">Po</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="order in orders" :key="order.id">
                                    <th scope="row">{{order.po}}</th>
                                    <td align="right">
                                        <button class="btn btn-outline-danger" @click="removeOrder(order)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                </div>
            </div>

            

        </div>

        
        
    </div>
    
</template>

<script>
import db from '@/firebase/init'

// @ is an alias to /src
import { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'OrdersSelector',
  components: { MoreVerticalIcon,ChevronLeftIcon,ChevronRightIcon },
  props: ['orders','ordersearch'],
  data(){
    return{
        order: null,
        editing:false,
        showsearch:false,
        searchvalue: '',
    }
  },
  methods:{
      resetOrder: function(){
          this.order = null
          this.showsearch = false
          this.searchvalue = ''
      },
      addOrder: function(){
          this.orders.push(this.order)
          this.updateOrder()
      },
      editOrder: function(order){
          this.order = order
      },
      updateOrder: function(){
          this.resetOrder()
      },
      removeOrder:function(order){
           var index = this.orders.findIndex(function(o){
                return o.id === order.id;
            })
            if (index !== -1) this.orders.splice(index, 1);
      },
      newOrder: function(ord){
        this.order = ord
        if(!this.orders.some(ord => ord.id === this.order.id)) return this.addOrder();
        this.resetOrder()
      }
  },
  computed:{
      filtered_orders(){
            let ords = this.ordersearch.filter(ord => {
                return (
                ord.po
                    .toLowerCase()
                    .indexOf(this.searchvalue.toLowerCase()) != -1
                );
            });

            return ords;
        }
  },
  filters:{
      formatStreet: function(order){
          return `${order.street} ${order.box}, ${order.zipcode} ${order.state} ${order.country}`
      }
  }
}
</script>