<template>

<nav class="navbar navbar-expand-lg navbar-dark bg-dark border-bottom shadow-sm">
  <div class="container-fluid px-1">
    <router-link :to="{name:'Products'}" class="navbar-brand" href="#">Inventory</router-link>
    <button class="navbar-toggler px-1 py-1 border text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <menu-icon size="1.2x"></menu-icon>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <router-link :to="{name:'Products'}" class="nav-link">
                <span>Products</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name:'Reports'}" class="nav-link">
                <span>Reports</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name:'Orders'}" class="nav-link">
                <span>Orders</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name:'Shipments'}" class="nav-link">
                <span>Shipments</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name:'Warehouses'}" class="nav-link">
                <span>Warehouses</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{name:'Manufacturers'}" class="nav-link">
                <span>Manufacturers</span>
            </router-link>
        </li>
      </ul>
      
      <ul class="list-unstyled m-0">
        <li class="nav-item dropdown">
          <a class="nav-link text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <p class="mb-0 w-100 d-flex justify-content-between align-items-center"><span>{{user.email}}</span><more-vertical-icon size="1.2x" class="text-muted"></more-vertical-icon></p>
          </a>
          <ul class="dropdown-menu w-100 shadow-sm" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#" @click.prevent="signout">Sign out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

</template>

<script>
import firebase from 'firebase'
// @ is an alias to /src
import { MenuIcon,MoreVerticalIcon} from 'vue-feather-icons'

export default {
  name: 'Topbar',
  components: {
    MenuIcon,MoreVerticalIcon
  },
  props: ['user'],
  data(){
    return{
    }
  },
  created(){
    // Create an instance.
    
  },
  methods:{
    selectTeam: function(team){
        this.user.team = team 
        this.$router.push({name:'Products',params:{team_id:team.id}})
    },
    signout: function(){
        firebase.auth().signOut().then(() => {
        this.$router.push({name:'Signin'})
        }).catch((error) => {
        // An error happened.
        });

    }
  }
}
</script>