<template>
  <section class="dashboard d-flex align-items-center justify-content-center bg-light" style="min-height: 100vh;">
    <div class="container" style="max-width:500px;">
        <aside class="card shadow-sm">
            <header class="card-header text-center p-3">
                <h1 class="h2">Sign up now</h1>
                <p class="lead mb-0">Already have an account? <router-link :to="{name:'Signin'}" class="text-primary">Sign in</router-link></p>

                
                <p v-if="success" class="alert alert-success text-center">{{success}}</p>
                <p v-if="error" class="alert alert-danger text-center">{{error}}</p>
            </header>
            <form action="" @submit.prevent="registerUser" id="fenito-register" class="card-body p-4">
                <fieldset class="form-group mb-2">
                    <label for="full-name">Full name</label>
                    <input type="text" class="form-control" placeholder="Full name" id="full-name" name="full-name" required v-model="user.fullName"/>
                </fieldset>
                <fieldset class="form-group mb-2">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" placeholder="Email address" id="email" name="email" required v-model="user.email"/>
                </fieldset>
                <fieldset class="form-group mb-2">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" placeholder="Password" id="password" name="password" aria-describedby="passwordHelp" required v-model="user.password"/>
                    <small id="passwordHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                </fieldset>
                <div class="form-check form-group mb-3">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" required>
                    <label class="form-check-label" for="exampleCheck1"><small>Check me out</small></label>
                </div>
                <button type="submit" class="btn btn-primary btn-block w-100">
                    <span v-if="!loading">Create Account Now</span>
                    <span v-else class="d-inline-block d-flex align-items-center justify-content-center">
                    <span class="spinner-border spinner-border-sm m-0 mr-1" role="status" aria-hidden="true"></span>
                    Creating account...
                    </span>
                </button>
            </form>
        
        </aside>
        <p class="text-center py-3 small text-muted">Copyright &copy; Renting app 2020-2021. All rights reserved</p>
    </div>
      
  </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'

export default {
  name: 'Signup',
  components: {
  },
  data(){
    return {
      user:{
        email : null,
        fullName: null,
        password: null
      },
      error:null,
      success:null,
      loading:false,
    }
  },
  methods:{
    registerUser(event){

      if (!this.user.fullName) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.user.email) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.user.password) {
        this.errors = true;
      } else {
        this.errors = false;
      }

      if (!this.errors) {

        let ref = db.collection('users').doc(this.user.email)
        ref.get().then(doc => {
          if (doc.exists) {
            this.signuperror = 'This email already exists'
          } else {

          firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
          .then(cred => {
            ref.set({
              fullName: this.user.fullName,
            })
            /*analytics.logEvent('sign_up', {
              email: this.user.email,
              first_name: this.user.firstName,
              last_name: this.user.lastName
            });*/
          }).then(()=>{
            this.$router.push({
              name: `Today`
            })
          })
          .catch(err => {
            console.log(err);
            this.signuperror = err.message
          })
        }
        })

        
      }
    }
  }
}
</script>
