<template>
    <section class="new-report">
    <form action="" @submit.prevent="updateReport" id="inventory-new-report" v-if="report" @change="edited=true">
        <header class="py-3 py-md-4 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <button  @click.prevent="$router.push({name:'Reports'})" class="btn px-2 btn-outline-secondary bg-white text-dark me-3">
                    <arrow-left-icon size="1.2x"></arrow-left-icon>
                </button>
                <div>
                    <h2 class="h3 m-1">{{report.name}}</h2>
                    <p class="text-muted mb-0">As of {{ report.updated | endOfMonth }} EOM</p>
                </div>
            </div>
            <div v-if="!editing">
                <button @click.prevent="createpdf" class="btn btn-primary me-2">Export</button>
                <button @click.prevent="editing = true" class="btn border">Edit</button>
            </div>
            <div v-else>
                <fieldset class="form-group d-flex align-items-center justify-content-between">
                    <button @click.prevent="reset" class="btn border me-2">Cancel</button>
                    <button class="btn btn-primary" :class="{'disabled':!edited}">
                        Update
                    </button>
                </fieldset>
            </div>
        </header>
        <div class="row">

            <Detail class="col-12 border-bottom" :user="user" :report="report" :editing="editing"/>

            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="2000"
                :filename="report.name"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a3"
                pdf-orientation="landscape"
                pdf-content-width="1550px"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                    <ExportReportTable :user="user" :report="report"/>
                </section>
            </vue-html2pdf>

            
            
        </div>
        
    </form>
    </section>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import firebase from 'firebase'
import db from '@/firebase/init'
import { ArrowLeftIcon} from 'vue-feather-icons'
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import Detail from '@/components/reports/Detail.vue'
import ExportReportTable from '@/components/reports/ExportReportTable.vue'


export default {
  name: 'DetailReport',
  components: {
      ArrowLeftIcon,
      Detail,moment,
      ExportReportTable,VueHtml2pdf
  },
  props: ['user'],
  data(){
    return {
        oldreport:null,
        report:null,
        edited:false,
        editing:false
    }
  },
  updated(){
      
      this.edited = (this.oldreport != JSON.stringify(this.report) )
  },
  created(){

      if(!this.$route.params.report) this.$router.push({name:'Reports'})
      if(!this.user.warehouses.length) this.$router.push({name:'NewWarehouses'})
      this.oldreport = this.$route.params.report
      this.reset()
  },
  filters:{
      endOfMonth: function(date){
        return !date ? '' :  moment(date).format('MMMM YYYY')
      },
      formatDate: function(date){
        return !date ? '' :  moment(date).format('MM/DD/YYYY hh:mm')
      }
  },
  methods:{
     createpdf: function () {
         this.$refs.html2Pdf.generatePdf()
        
    },
    updateReport: function(){

        let id = this.report.id
        let updatedProduct = this.report
        delete updatedProduct.id

        db.collection("reports").doc(id).update(updatedProduct)
        .then(() => {
            console.log("Document successfully updated!");
            this.report.id = id
            this.oldreport = this.report
            this.reset()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        })
    },
    reset: function(){ 

        this.edited = false
        this.editing = false
        this.oldreport = typeof this.oldreport == 'string' ? JSON.parse(this.oldreport) : this.oldreport
        
        this.report = this.oldreport
        this.oldreport = JSON.stringify(this.oldreport)
    }
  }
}
</script>

<style lang="scss">
    .vue-barcode-element{
            margin: 0 auto !important;
            width: 100% !important;
    }
</style>
